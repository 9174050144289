import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

import { Button, Typography, Icon, HR } from '@team-seenit/atoms';
import { StaticPage } from '@team-seenit/molecules';
import { Error, LineItem, TierFeature } from '../../../molecules';

import * as Styled from './Brief.styled';

export function OrganisationBriefPresenter({
  availableAddons,
  fetchError,
  tierId,
  dataEl,
  errors,
  handleFormChanges,
  getValue,
  isformValid,
  submitted,
  handleFormSubmission,
  organisation,
  hasOrganisationChanged,
}): JSX.Element {
  const { push } = useHistory();

  if (fetchError) {
    return (
      <StaticPage
        body={`Tier with id ${tierId}`}
        buttonProps={{
          children: 'Back to tier list',
          dataEl: 'DataEl',
          onClick: () => push('/Organisations'),
        }}
        dataEl="LibraryLoading"
        emoticonProps={{
          dataEl: 'LibraryLoadingEmoji',
          emoji: null,
          emojiComponent: (
            <Icon
              color="red"
              dataEl="LibraryLoadingIcon"
              name="error"
              size="massive"
            />
          ),
        }}
        title="Error fetching data for"
      />
    );
  }

  return (
    <Styled.Wrapper dataEl={dataEl}>
      <Error rawErrors={errors} />

      <Typography dataEl="CreateTier" variant="header">
        Organisation details
      </Typography>

      <LineItem text={organisation?.orgId} title="Org ID" />
      <LineItem text={organisation?.name} title="Name" />
      <LineItem text={organisation?.nickname} title="Nickname" />

      <HR />
      <Typography dataEl="CreateTier" variant="body">
        Current tier
      </Typography>

      <LineItem text={organisation?.tier?.tierId} title="Tier ID" />
      <LineItem text={organisation?.tier?.name} title="Tier name" />
      <LineItem
        text={organisation?.tier?.description}
        title="Tier description"
      />

      <HR />
      <Typography dataEl="CreateTier" variant="header">
        Addons
      </Typography>

      <HR />
      <Typography dataEl="CreateTier" variant="header">
        Available Addons
      </Typography>
      {availableAddons.map(feat => {
        return (
          <>
            <TierFeature
              dataEl={`AVAILABLE_ADDON-${feat.registeredFeatureId}`}
              getValue={getValue}
              handleFormChanges={handleFormChanges}
              item={{
                registeredFeatureId: `addon-feature-${feat.registeredFeatureId}`,
                name: feat.name,
                description: feat.description,
                valueIndicator: feat.valueIndicator,
                dataEl: `ADDON-Feature-${feat.registeredFeatureId}-DataEl`,
                type: feat.type,
                enableReadonly: feat.enableReadonly,
                enableReadonlyMessage: feat.enableReadonlyMessage,
                startDateReadonly: feat.startDateReadonly,
                startDateReadonlyMessage: feat.startDateReadonlyMessage,
              }}
            />
            <HR />
          </>
        );
      })}

      <Styled.Buttons>
        <Button
          disabled={!hasOrganisationChanged || !isformValid()}
          loading={submitted}
          onClick={handleFormSubmission}
          rounded
        >
          Update organisation
        </Button>

        <Button
          backgroundColor="yellow"
          loading={submitted}
          onClick={() => push(`/tiers`)}
          rounded
        >
          Return to Tiers list
        </Button>
      </Styled.Buttons>
    </Styled.Wrapper>
  );
}

OrganisationBriefPresenter.propTypes = {
  /** DataEl for the component. */
  dataEl: PropTypes.string,
  /** DataEl for the component. */
  tierId: PropTypes.string,
  // /** Component title */
  // title: PropTypes.string.isRequired,
  // /** Component button title */
  // buttonTitle: PropTypes.string.isRequired,
  /** Function to get field value. */
  getValue: PropTypes.func.isRequired,
  /** Function to handle form changes. */
  handleFormChanges: PropTypes.func.isRequired,
  /** Function to handle form submission. */
  handleFormSubmission: PropTypes.func.isRequired,
  /** Function to handle delete. */
  // handleDeleteClick: PropTypes.func.isRequired,
  // /** GQL fetch errors */
  fetchError: PropTypes.objectOf(PropTypes.any),
  /** GQL errors */
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  /** Boolean field of the state of the form */
  submitted: PropTypes.bool.isRequired,
  /** Boolean field to say the form is valid */
  isformValid: PropTypes.bool.isRequired,
};

OrganisationBriefPresenter.defaultProps = {
  dataEl: 'OrganisationBriefPresenter',
  fetchError: undefined,
  tierId: undefined,
};
