import React, { useState } from 'react';
import { useQuery } from '@apollo/client';

import { EditOrganisation } from './EditOrganisation';
import {
  GET_REGISTERED_FEATURES_QUERY,
  destructureDataForRegisteredFeatures,
} from './Brief.gql';

export function OrganisationBrief({ orgId }): JSX.Element {
  const [submitted, setSubmitted] = useState<boolean | false>();
  const [userData, setUserData] = useState({});

  const { data: registeredFeaturesData } = useQuery(
    GET_REGISTERED_FEATURES_QUERY,
    {
      fetchPolicy: 'cache-and-network',
    }
  );

  const { registeredFeatures } = destructureDataForRegisteredFeatures(
    registeredFeaturesData
  );

  const setAddonProp = (value, id, valid) => {
    let baseProp = { [id]: { ...userData[id], value, valid } };
    if (id.startsWith('addon-') && id.endsWith('-enable')) {
      const startdateId = id.replace(/-enable/, '-startdate');
      const enddateId = id.replace(/-enable/, '-enddate');
      baseProp = {
        ...baseProp,
        [startdateId]: { ...userData[startdateId], required: value },
        [enddateId]: { ...userData[enddateId], required: value },
      };
    }

    return baseProp;
  };

  const handleFormChanges = (value, { id, valid }) => {
    setUserData({ ...userData, ...setAddonProp(value, id, valid) });
  };

  const getValue = formKey => {
    return userData[formKey];
  };

  // const checkAddon = () => {
  //   return Object.keys(userData).some(
  //     formKey => formKey.includes('-enable') && userData[formKey].value === true
  //   );
  // };

  const organisationChanged = () => {
    return true;
    // return checkAddon();
  };

  const isformValid = () => {
    return Object.keys(userData).every(
      formKey =>
        (userData[formKey].required &&
          !!userData[formKey].value &&
          userData[formKey].valid) ||
        (!userData[formKey].required && userData[formKey].valid)
    );
  };

  return (
    <EditOrganisation
      getValue={getValue}
      handleFormChanges={handleFormChanges}
      hasOrganisationChanged={organisationChanged()}
      isformValid={isformValid}
      orgId={orgId}
      registeredFeatures={registeredFeatures}
      setSubmitted={setSubmitted}
      setUserData={setUserData}
      submitted={submitted}
      userData={userData}
    />
  );
}
