import React from 'react';
import { useReactiveVar } from '@apollo/client';
import PropTypes from 'prop-types';

import { Typography, UnorderedList } from '@team-seenit/atoms';
import { quickEditThemeFormErrorVar } from '../variables';

import * as Styled from './Error.styled';
import { destructureError } from './destructureError';

export const Error = ({ dataEl }) => {
  const quickEditThemeFormError = useReactiveVar(quickEditThemeFormErrorVar);

  const errors = destructureError(quickEditThemeFormError);

  return (
    <Styled.Wrapper dataEl={dataEl}>
      <UnorderedList>
        {errors.map((error, index) => (
          <li key={`error-${index}`}>
            <Typography color="red" variant="subtext">
              {error}
            </Typography>
          </li>
        ))}
      </UnorderedList>
    </Styled.Wrapper>
  );
};

Error.propTypes = {
  /** Set data-el attribute. */
  dataEl: PropTypes.string,
};

Error.defaultProps = {
  dataEl: 'Error',
};
