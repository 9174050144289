import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';

import PropTypes from 'prop-types';

import { AUTH } from '@team-seenit/constants';
import { Button } from '@team-seenit/atoms';
import { CloudinaryUploader } from '@team-seenit/organisms';
import { useAuth, useTheme } from '@team-seenit/contexts';

import { organisationStatsByOrgIdVar } from '../variables';
import * as Styled from './MediaConfig.styled';

import { CREATE_QUICK_EDIT_MEDIUM_MUTATION } from './MediaConfig.gql';

const { USER_ID_KEY } = AUTH;

export const MediaConfig = ({ dataEl, currentTab, orgId }): JSX.Element => {
  const { isDesktop } = useTheme();
  const { user: { [USER_ID_KEY]: userId } = {} } = useAuth();

  const [uploadItems, setUploadItems] = useState([]);

  const [
    createQuickEditMedium,
    { data: createdMedium, loading: creatingMedium },
  ] = useMutation(CREATE_QUICK_EDIT_MEDIUM_MUTATION);

  const handleChange = (id, data) => {
    setUploadItems(currUploadItems =>
      currUploadItems.map(currUploadItem => {
        if (currUploadItem.id === id) return { ...currUploadItem, ...data };
        return currUploadItem;
      })
    );
  };

  const preset = 'intro_video';
  const fontsPreset = 'fonts_authenticated_raw';

  const handleDelete = id => {
    setUploadItems(currUploadItems =>
      currUploadItems.filter(currUploadItem => currUploadItem.id !== id)
    );
  };

  const handleNewUpload = (id, data) => {
    setUploadItems(currUploadItems => [...currUploadItems, { id, ...data }]);
  };

  useEffect(() => {
    if (createdMedium) {
      organisationStatsByOrgIdVar({ reload: true });
      setUploadItems([]);
    }
  }, [createdMedium]);

  const mutationDataItem = item => ({
    deliveryType: item.deliveryType,
    approvedBy: userId,
    fileFormat: currentTab === 'fonts' ? 'OTF' : item.fileFormat,
    accessMode: item.accessMode,
    accessControl: item.accessControl,
    isAudio: item.isAudio,
    duration: item.duration,
    eager: item.eager,
    mediaType: currentTab,
    mediaFormat: currentTab === 'fonts' ? 'video' : item.mediaFormat,
    orgId,
    publicId: item.publicId,
    thumbnailUrl:
      item.mediaFormat === 'video'
        ? `${item.uploadUrl.match(/(.+)\./)[1]}.jpg`
        : item.uploadUrl,
    uploadedBy: userId,
    uploadUrl: item.uploadUrl,
    uploadPreset: `${currentTab === 'fonts' ? fontsPreset : preset}`,
  });

  const onClick = () => {
    uploadItems.map(item => {
      createQuickEditMedium({
        variables: {
          data: mutationDataItem(item),
        },
      });
    });
  };

  const uploadsComplete =
    Array.isArray(uploadItems) &&
    uploadItems.length &&
    uploadItems.every(({ status }) => status === 200);

  const disabled =
    !uploadItems ||
    (Array.isArray(uploadItems) && !uploadItems.length) ||
    uploadsComplete;

  const mediaTypeForUploader = () => {
    if (currentTab === 'audioTracks') return 'audio';
    if (currentTab === 'fonts') return 'font';
    return ['openingMedia', 'closingMedia'].includes(currentTab)
      ? 'video'
      : 'image';
  };

  return (
    <Styled.Wrapper dataEl={dataEl}>
      <Styled.TabSideWrapper>
        <CloudinaryUploader
          columns={isDesktop ? 3 : 2}
          folder={`${orgId}/${currentTab}`}
          // metadata={{
          //   [METADATA.PROJECT_ID]: projectId,
          //   [METADATA.SUBMITTED]: false,
          //   [METADATA.UPLOAD_BATCH]: uploadBatchId,
          // }}
          mediaType={mediaTypeForUploader()}
          onChange={handleChange}
          onDelete={handleDelete}
          onNewUpload={handleNewUpload}
          preset={`${currentTab === 'fonts' ? fontsPreset : preset}`}
          // uploadCardAspectRatio={isDesktop ? '320 / 140' : '164 / 108'}
          showUploadCaptions
          // uploadItemsAspectRatio="1 / 1"
          uploadItems={uploadItems}
          // useCameraVisible
        />
      </Styled.TabSideWrapper>

      <Styled.TabSideWrapper>
        <Button
          animation={uploadsComplete ? 'pulse' : undefined}
          dataEl="MediaConfigSubmitButton"
          disabled={!uploadsComplete || creatingMedium}
          id="openingMediaSubmit"
          inactive={!uploadsComplete}
          large
          onClick={uploadsComplete ? onClick : undefined}
          type="submit"
          variant={!uploadsComplete && !disabled ? 'translucent' : 'primary'}
          width="100%"
        >
          Submit
        </Button>
      </Styled.TabSideWrapper>
    </Styled.Wrapper>
  );
};

MediaConfig.propTypes = {
  /** Set data-el attribute. */
  dataEl: PropTypes.string,
  /** Org id */
  orgId: PropTypes.string.isRequired,
  /** current tab. */
  currentTab: PropTypes.string.isRequired,
};

MediaConfig.defaultProps = {
  dataEl: 'MediaConfig',
};
