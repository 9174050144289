import React from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider } from '@apollo/client';
import { AppProvider, UserProvider, useAuth } from '@team-seenit/contexts';
import { PATHS, AUTH } from '@team-seenit/constants';

import * as Sentry from '@sentry/react';

import { Switch, BrowserRouter, Route } from 'react-router-dom';
import {
  GetStartedPage, // This has memory leak issue, need to use this rather than Home
  NotFoundPage,
  LoginPage,
  LoginCallbackPage,
  LogoutPage,
  LogoutCallbackPage,
} from '@team-seenit/pages';
import { createApolloClient } from './config';
import {
  LandingPage,
  TiersPage,
  TogglesPage,
  NotAuthorisedPage,
  QuickEditsPage,
} from './pages';

import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datepicker/dist/react-datepicker.css';

function App() {
  const { loading, isAuthenticated, user = {}, accessToken } = useAuth() || {};
  const { [AUTH.USER_ROLES_KEY]: roles = [] } = user;

  if (loading) return null;

  const apolloClient = createApolloClient({ logger: console, accessToken });

  const ProtectedRoutes = ({ children }) => (
    <Route
      render={() => {
        if (!isAuthenticated) return <GetStartedPage />;

        if (!roles.includes('seenit-admin')) return <NotAuthorisedPage />;

        return children;
      }}
    />
  );

  ProtectedRoutes.propTypes = {
    children: PropTypes.elementType.isRequired,
  };

  return (
    <BrowserRouter>
      <AppProvider>
        <ApolloProvider client={apolloClient}>
          <UserProvider user={user}>
            <Switch>
              <Route path={PATHS.LOGOUT}>
                <LogoutPage />
              </Route>

              <Route path={PATHS.LOGIN}>
                <LoginPage />
              </Route>

              <Route exact path={PATHS.LOGIN_CALLBACK}>
                <LoginCallbackPage />
              </Route>

              <Route path={PATHS.LOGOUT_CALLBACK}>
                <LogoutCallbackPage />
              </Route>

              <ProtectedRoutes>
                <Route exact path="/">
                  <LandingPage />
                </Route>

                <Route exact path="/tiers">
                  <TiersPage />
                </Route>

                <Route exact path="/tiers/create">
                  <TiersPage />
                </Route>

                <Route exact path="/tiers/:tierId/setup">
                  <TiersPage />
                </Route>

                <Route exact path="/tiers/:tierId/organisations">
                  <TiersPage />
                </Route>

                <Route exact path="/tiers/features">
                  <TiersPage />
                </Route>

                <Route exact path="/tiers/features/create">
                  <TiersPage />
                </Route>

                <Route exact path="/tiers/features/:registeredFeatureId/setup">
                  <TiersPage />
                </Route>

                <Route exact path="/tiers/organisations">
                  <TiersPage />
                </Route>

                <Route exact path="/toggles">
                  <TogglesPage />
                </Route>

                <Route exact path="/quick-edits">
                  <QuickEditsPage />
                </Route>
              </ProtectedRoutes>

              <Route path="*">
                <NotFoundPage />
              </Route>
            </Switch>
          </UserProvider>
        </ApolloProvider>
      </AppProvider>
    </BrowserRouter>
  );
}

export default Sentry.withProfiler(App);
